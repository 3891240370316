@import '../../../assets/theme';
@import '../../../assets/globals';

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: $primary-color-background;
  border: 4px solid $primary-color-accent;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
  @media screen and (prefers-color-scheme: dark) {
    background-color: $dark-primary-color-background;
    border: 4px solid $dark-primary-color-accent;
  }
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid $primary-color-accent;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent $primary-color-accent;
  @media screen and (prefers-color-scheme: dark) {
    border-color: transparent transparent transparent $dark-primary-color-accent;
  }
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid $primary-color-accent;
  border-width: 10px 10px 10px 0;
  border-color: transparent $primary-color-accent transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  @include primary-box-border;
  @include card-padding;
  position: relative;
}

.show-more {
  margin: auto;
  font-size: x-large;
  display: block;
  width: 100%;
  text-align: center;
  position:relative;
  z-index: 1;
  transition: transform .2s ease-in-out;
  
}

.show-more:hover {
  transform: scale(1.2);
}

.last-p {
  margin-bottom: 7px;
}

.show-more:hover {
  cursor: pointer;
}

.rotate-right {
  animation: rotate-right 0.5s ease;
  animation-fill-mode: forwards;  
}

@keyframes rotate-right {
  from {transform: rotate(0deg)}
  to {transform: rotate(180deg)}
}

.rotate-left {
  animation: rotate-left 0.5s ease;
  animation-fill-mode: forwards;  
}

@keyframes rotate-left {
  from {transform: rotate(180deg)}
  to {transform: rotate(360deg)}
}

.show-extra-info {
  max-height: 500px;
  opacity: 1;
  transition: all 2s ease;
}

.hide-extra-info {
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s ease;
}

/* Media queries - Responsive timeline on screens less than 700px wide */
@media screen and (max-width: 700px) {
  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 0px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid $primary-color-accent;
    border-width: 10px 10px 10px 0;
    border-color: transparent $primary-color-accent transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}