@import '../../assets/theme';
@import '../../assets/globals';

$nav-bar-size: -57px;

#navbar {
    background-color: $primary-color-dark;
    color: white;
    opacity: 0.8;
    position: fixed;
    top: $nav-bar-size;
    width: 100%;
    transition: top 0.3s;
    z-index: 2;
    border-radius: 0px 0px 10px 10px;
}

#navbar>ul {
    margin: -1px;
    padding: 0px;
}

@mixin nav-link {
    padding: 12px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 23px;
    font-family: Pacifico, cursive;
}

.nav-item {
    @include nav-link;
    width: 20%;
    /* Five equal-width links. If you have two links, use 50%, and 33.33% for three links, etc.. */
    text-align: center;
    /* If you want the text to be centered */
    float: left;
}

.nav-item:hover {
    background-color: $primary-color;
    font-weight: bold;
    transition: background-color .5s ease-in-out, font-weight 0.5s ease-in-out;
    border-radius: 0px 0px 10px 10px;
}

.active-link {
    background-color: $primary-color;
    font-weight: bold;
    transition: background-color .5s ease-in-out, font-weight 0.5s ease-in-out;
    border-radius: 0px 0px 10px 10px;
}

.hidden-navbar {
    top: $nav-bar-size;
}

.turn-right {
    animation: turn-right .2s ease;
}

.turn-left {
    animation: turn-left .2s ease;
}

.go-to-top-icon {
    position: fixed;
    /* Fixed/sticky position */
    bottom: 20px;
    /* Place the button at the bottom of the page */
    right: 30px;
    /* Place the button 30px from the right */
    z-index: 99;
    /* Make sure it does not overlap */
    color: $primary-color-accent;
    font-size: xx-large;
    transition: all 0.2s ease;
    opacity: 1;
    cursor: pointer;
}

.go-to-top-icon:hover,
.go-to-top-icon:active {
    transform: scale(1.1);
}

.hidden-icon {
    opacity: 0;
    cursor: auto;
}

@keyframes turn-right {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(90deg)
    }
}

@keyframes turn-left {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(-90deg)
    }
}

.hamburger {
    @include nav-link;
    background-color: transparent;
    display: none;
    padding-right: 30px;
}

.visible-navbar {
    top: 0px !important;
}

@media screen and (max-width: 600px) {
    .nav-item {
        display: none;
    }

    .hamburger {
        float: none;
        text-align: right !important;
        width: 100% !important;
        display: block;
    }

    .responsive-visible {
        float: none;
        display: block;
        width: 100% !important;
        animation: show-nav 0.2s ease;
    }

    @keyframes show-nav {
        from {
            transform: scale(1, 0)
        }

        to {
            transform: scale(1, 1)
        }
    }
}