@import '../../assets/globals';
@import '../../assets/theme';

.button {
    @include border-style;
    @include center-horizontal;
    width: 245px;
    height: 50px;
    color: white;
    font-size: 1.3rem;
    background-color: $primary-color-accent;
    border-width: 0px;
    border-color: $primary-color-background;
    transition: border-width 0.5s;
    position:relative;
    z-index: 1;
    cursor: pointer;
    @media screen and (prefers-color-scheme: dark) {
        border-color: $dark-primary-color-background;
        background-color: $dark-primary-color-accent;
    }
}

.button:hover {
    border-width: 5px;
}

button:active {
    transform: scale(1.05);
}