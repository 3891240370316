@import './assets/globals';
@import './assets/theme';

body, html {
  height: 100%;
  background-color: $secondary-color;
  scroll-behavior: smooth;
  @media screen and (prefers-color-scheme: dark) {
    background-color: $dark-secondary-color;
  }
}

.page {
  margin: 0px 40px;
}

p {
  font-size: 1.2rem;
}