@import '../../assets/globals';
@import '../../assets/theme';

.secondary-light-bg {
    background-color: $secondary-color-lighter;
    margin: 0px;
    @media screen and (prefers-color-scheme: dark) {
      background-color: $dark-primary-color-background;
    }
}

.projects {
  @include max-section-width;
}
  
/* Remove extra left and right margins, due to padding in columns */
.row {margin: 0 -5px;
  }

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.filled-boats{
  animation: boat-on-wave 7s ease-in-out infinite;
  user-select: none;
  background-image: url('../../assets/boatsfilled.svg');
  height: 21vw;
  background-repeat: no-repeat;
  @media screen and (prefers-color-scheme: dark) {
    background-image: url('../../assets/boatsfilled-dark.svg');
  }
}

@keyframes boat-on-wave {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
}