@import '../../assets/globals';
@import '../../assets/theme';

$carousel-padding: 28px;

* {box-sizing:border-box}

/* Slideshow container */
.carousel-container {
    @include border-style;
    padding: 15px $carousel-padding;
    padding-bottom: 20px;
    color: $primary-color-accent;
    background-color: $primary-color-background;
    width: 45%;
    position: relative;
    display: block;
    margin: auto; 
    @media screen and (prefers-color-scheme: dark) {
        color: $dark-primary-color-accent;
        background-color: $dark-primary-color-background;
    }
}

.carousel-title {
    margin-bottom: 15px;
}

.carousel-img {
    max-width: 100%;
    border-radius: 5px;
    user-select: none;
    margin-bottom: 10px;
}

/* Next & previous buttons */
.prev, .next {
    @include border-style;
    position: absolute;
    top: 50%;
    margin-top: -22px;
    height: 50px;
    width: 50px;
    background-color: $primary-color-accent;
    color: $primary-color-background;
    border-color: $primary-color-background;
    border-width: 0px;
    border-radius: 50%;
    font-weight: bolder;
    font-size: x-large;
    transition: 0.6s ease;
    user-select: none;
    @media screen and (prefers-color-scheme: dark) {
        background-color: $dark-primary-color-accent;
        color: $dark-primary-color-background;
        border-color: $dark-primary-color-background;
    }
}

.center-inside{
    @include center-vertical;
}

/* Position the "next button" to the right */
.next {
    right: -$carousel-padding;
}

.prev {
    left: -$carousel-padding;
}

/* On hover, add a border */
.prev:hover, .next:hover {
    border-width: 5px;
}

/* The dots/bullets/indicators */
.dot {
    cursor: pointer;
    margin: 0 5px;
    width: 25px;
    height: 25px;
    background-color: $primary-color-background;
    border: 4px solid $primary-color-accent;
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    transition: background-color 0.6s ease;
    position:relative;
    z-index: 1;
    @media screen and (prefers-color-scheme: dark) {
        background-color: $dark-primary-color-background;
        border: 4px solid $dark-primary-color-accent;
    }
}

.carousel-dots {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.active, .dot:hover {
    background-color: $primary-color-accent;
    @media screen and (prefers-color-scheme: dark) {
        background-color: $dark-primary-color-accent;
    }
}

/* Fading animation, two different names so can switch when changing image and replay animation */
.fade {
    animation: fade-in 1s ease-in;
}

/* Fading animation */
.fade2 {
    animation: fade-in2 1s ease-in;
}

/* Caption text */
.text {  
    color: white;
    padding: 15px;
    opacity: 0.9;
    background-color: $secondary-color;
    width: 100%;
    //margin-top: -70px !important;
    height: 130px;
    margin: auto;
    text-align: center;
    border-radius: 5px;
    @media screen and (prefers-color-scheme: dark) {
        background-color: $dark-secondary-color;
    }
}

@keyframes fade-in {
    from {opacity: 0.4;}
    to {opacity: 1;}
}

@keyframes fade-in2 {
    from {opacity: 0.4;}
    to {opacity: 1;}
}

@media screen and (max-width: 1000px) {
    .carousel-container {
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .carousel-container {
        width: 100%;
    }
}

@media screen and (max-width: 505px) {
    .text {
        height: 170px;
        width: 95%;
    }
}

@media screen and (max-width: 400px) {
    .text {
        height: 220px;
    }
}

@media screen and (max-width: 370px) {
    .text {
        height: 270px;
        width: 97%;
    }
}