@import '../../assets/globals';
@import '../../assets/theme';

.bg {
  height: 100vh;
  background-color: $primary-color-background;
  @media screen and (prefers-color-scheme: dark) {
    background-color: $dark-primary-color-background;
  }
}

.light-primary-wave {
  fill: $primary-color-background;
  @media screen and (prefers-color-scheme: dark) {
    fill: $dark-primary-color-background;
  }
}

.circle {
  @include border-style;
  @include border-padding;
  @include center-vertical;
  border-radius: 50%;
  background-color: $primary-color-background-lighter;
  border-color: $primary-color;
  @media screen and (prefers-color-scheme: dark) {
    background-color: $dark-primary-color-background-lighter;
    border-color: $dark-primary-color-accent;
  }
}

.logo {
  display:block;
  margin:auto;
  width: 50%;
  margin-top: 1rem;
}

.circle div{
  margin: 1rem;
}