@import '../../assets/theme';
@import '../../assets/globals';

* {
box-sizing: border-box;
}

.timeline-title {
  @include border-padding;
  @include center-horizontal;
  border-radius: 50%;
  width: 250px;
  height: 170px;
  background-color: $primary-color;
  border-color: $primary-color;
  @media screen and (prefers-color-scheme: dark) {
    background-color: $dark-primary-color-background;
    border-color: $dark-primary-color-background;
  }
}

/* The actual timeline (the vertical ruler) */
.timeline {
  @include max-section-width;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: $primary-color;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  @media screen and (prefers-color-scheme: dark) {
    background-color: $dark-primary-color-background;
  }
}

/* Media queries - Responsive timeline on screens less than 700px wide */
@media screen and (max-width: 700px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  .timeline-title {
    margin: 0px;
    border-radius: 32px;
  }
}