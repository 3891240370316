@import 'globals';

@mixin box-radius {
    border-radius: 10px;
}

@mixin border-style {
    @include box-radius;
    border-width: 5px;
    border-style: dashed;
}

@mixin primary-box-border  {
    @include border-style;
    @include box-radius;
    border-color: $primary-color-accent;
    background-color: $primary-color-background-lighter;
    @media screen and (prefers-color-scheme: dark) {
        border-color: $dark-primary-color-accent;
        background-color: $dark-primary-color-background-lighter;
    }
}

@mixin secondary-box  {
    @include box-radius;
    background-color: $secondary-color-background;
    @media screen and (prefers-color-scheme: dark) {
        background-color: $dark-secondary-color-background;
    }
}

@mixin secondary-box-border  {
    border-width: 0px;
    border-bottom-width: 5px;
    border-style: dashed;
    background-color: $secondary-color-background-lighter;
    border-color: $secondary-color;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-top: 20px;
    @media screen and (prefers-color-scheme: dark) {
        background-color: $dark-secondary-color-background-lighter;
        border-color: $dark-primary-color-accent;
    }
}

@mixin card-padding {
    padding: 20px 30px;
}

@mixin border-padding {
    padding: 35px;
}

@mixin center-horizontal{
    display: block;
    margin: auto;
}

@mixin center-vertical {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin max-section-width {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.title {
    font-size: 2.3rem;
    font-family: Pacifico, cursive;
    text-align: center;
    color: $secondary-color;
    @media screen and (prefers-color-scheme: dark) {
        color: white;
    }
}

.flex-container {
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

a.anchor {
    display: block;
    position: relative;
    top: -67px;
    visibility: hidden;
}

.company-logo {
    display:block;
    margin:auto;
    max-width: 100px;
    max-height: 70px;
    transition: all .2s ease-in-out;
    opacity: 0.8;
    @media screen and (prefers-color-scheme: dark) {
        opacity: 1;
    }
}

.company-logo:hover {
    transform: scale(1.1);
}

a {
    color: $primary-color-accent;
    text-decoration: none;
    transition: all .2s ease-in-out;
    @media screen and (prefers-color-scheme: dark) {
        color: $dark-primary-color-accent;
    }
}

a:hover {
    font-weight: bold;
    cursor: pointer;
}

.secondary-light-wave {
    fill: $secondary-color-lighter;
    @media screen and (prefers-color-scheme: dark) {
        fill: $dark-primary-color-background;
    }
}

@mixin svg-wave {
    animation: moving-wave 7s ease-in-out infinite;
    position:relative;
    z-index: 0;
}

.svg-wave-top {
    @include svg-wave;
    vertical-align:bottom;
    transform-origin: bottom;
    top: 3px;
}

@keyframes moving-wave {
    0%, 100% {transform: scale(1, 1)}
    50% {transform: scale(1, 1.23)}
}

.svg-wave-bottom {
    @include svg-wave;
    animation: moving-wave 7s ease-in-out infinite;
    transform-origin: top;
    top: -3px;
}

.space-under {
    margin-bottom: 40px;
}

@media screen and (prefers-color-scheme: dark) {
    p {
        color: white;
    }
    .small-text{
        color: white;
    }
}

::-moz-selection { background: $primary-color-accent; }
::selection { background: $primary-color-accent; }

/* width */
::-webkit-scrollbar {
    width: 15px;
}

  /* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    background-color: lightgrey;
}

  /* Handle */
::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
}

  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: dimgray;
}