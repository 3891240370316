@import '../../assets/globals';
@import '../../assets/theme';

.footer {
    width: 100%;
    background-color: $primary-color-background;
    text-align: center;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 60px;
    @media screen and (prefers-color-scheme: dark) {
        background-color: $dark-primary-color-background;
    }
}

.svg-wave {
    vertical-align:bottom;
}

.primary-light-wave {
    fill: $primary-color-background;
    @media screen and (prefers-color-scheme: dark) {
        fill: $dark-primary-color-background;
    }
}

.social-icon {
    font-size: 2.2rem;
    margin: 30px;
    color: $primary-color-accent;
    transition: color .8s ease-in-out;
    @media screen and (prefers-color-scheme: dark) {
        color: $dark-primary-color-accent;
    }
}

.linkedin:hover {
    color: #0e76a8;
}

.instagram:hover {
    color: #E1306C;
}

.github:hover {
    color: black;
}

.envelope:hover {
    color: rgb(24,142,232);
}

.made-by-text {
    color: $secondary-color;
    font-family: Pacifico, cursive;
    font-size: 1.5rem;
    padding-bottom: 30px;
    @media screen and (prefers-color-scheme: dark) {
        color: white;
    }
}
